.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.react-datepicker__input-container input {
  padding: 5px;
}
.cross {
  position: relative;
  display: inline-block;
}
.cross::before,
.cross::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
/*.cross::before {
  border-bottom: 2px solid green;
  -webkit-transform: skewY(-10deg);
  transform: skewY(-10deg);
}*/
.cross::after {
  border-bottom: 3px solid red;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
}
.disabled {
  filter: brightness(130%);
}
